import client from '@/api/client'

export async function getUsers ({ search, page, perPage }) {
  const response = await client.get('/users', {
    params: {
      ...(search && { search }),
      ...(page !== undefined && perPage !== undefined && { skip: (page - 1) * perPage }),
      ...(page !== undefined && perPage !== undefined && { limit: perPage })
    }
  })
  return response.data
}

export async function getUser ({ username }) {
  const response = await client.get(`/users/${username}`)
  return response.data
}

export async function update ({ username, roles, blocked }) {
  const response = await client.put(`/users/${username}`, { roles, blocked })
  return response.data
}
