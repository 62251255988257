import Vuex from 'vuex'
import * as actions from './actions'
import mutations from './mutations'

const state = {
  profile: {},
  progressBar: 0,
  topMessage: { active: false, msg: '', dismiss: false, type: '' }
}

const store = new Vuex.Store({
  state,
  actions,
  mutations
})

export default store
