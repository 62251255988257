export default Object.freeze({
  // Default user role
  user: 'user', // Enforced role
  // Administrator role (can manage users & user roles)
  admin: 'admin',
  // Has access to r2d2 service
  r2d2: 'r2d2', // Enforced role
  // Has access to crowd-sec service
  crowdSec: 'crowd-sec',
  // Has access to ldap-groups service
  ldapGroups: 'ldap-groups',
  // Has access to ldap-users service
  ldapUsers: 'ldap-users',
  // Has access to development statistics service
  developmentStatistics: 'development-statistics'
})
