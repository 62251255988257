<template>
  <div
    ref="dropdown"
    :class="{'dropdown--dropup': dropup}"
    class="dropdown"
    @click="toggle"
  >
    <slot name="dropdown-trigger" />
    <transition name="dropdown-slide-down">
      <div
        v-show="opened"
        :class="`dropdown--${size}`"
        class="dropdown__panel"
      >
        <slot name="dropdown-panel" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { createFocusTrap } from 'focus-trap'
import { onMounted, ref, toRefs } from 'vue'

const props = defineProps({
  size: {
    type: String,
    required: false,
    default: 'md'
  },
  dropup: {
    type: Boolean,
    required: false,
    default: false
  },
  closesOnClick: {
    type: Boolean,
    required: false,
    default: true
  }
})

const { closesOnClick } = toRefs(props)

const opened = ref(false)
const dropdown = ref(null)
let trap

onMounted(() => {
  trap = createFocusTrap(dropdown.value, {
    clickOutsideDeactivates: true,
    onActivate: () => { opened.value = true },
    onDeactivate: () => { opened.value = false }
  })
})

const toggle = () => {
  if (!closesOnClick.value) {
    trap.activate()
    return
  }
  if (opened.value) {
    trap.deactivate()
  } else {
    trap.activate()
  }
}
</script>

<style lang="scss">
@use "sass:color";
@import "@/assets/scss/variables";

.dropdown {
  position: relative;
}

.dropdown--xs {
  min-width: 6.25rem;
}

.dropdown--md {
  min-width: 11.5rem;
}

.dropdown--lg {
  min-width: 18.125rem;
}

.dropdown__panel {
  position: absolute;
  top: 1.5em;
  top: calc(100% + 7px);
  right: -0.5rem;
  word-wrap: none;
  white-space: nowrap;
  z-index: 10000;
  opacity: 1;
  transform: translateY(0);
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 10%), 0 5px 10px 0 rgb(0 0 0 / 15%);
}

.dropdown__panel::before {
  position: absolute;
  content: "";
  top: -0.5rem;
  right: 1rem;
  filter: drop-shadow(0 -2px 1px rgb(0 0 0 / 5%));
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid #fff;
}

.dropdown--dropup {
  .dropdown__panel {
    top: unset;
    bottom: calc(100% + 14px);

    &::before {
      top: unset;
      bottom: -0.5rem;
      filter: drop-shadow(0 2px 1px rgb(0 0 0 / 5%));
      border-bottom: none;
      border-top: 8px solid #fff;
    }
  }
}

.dropdown-slide-down-enter-active,
.dropdown-slide-down-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.dropdown-slide-down-enter,
.dropdown-slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-0.5rem);
}

// Global dropdown menu styles

.dropdown-user__details {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eef2f5;
}

.dropdown-user__text {
  margin-left: 16px;
}

.dropdown-user__text .name {
  font-weight: 600;
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-user__text .username {
  font-size: 16px;
  color: #717782;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropdown-menu__item ~ .dropdown-menu__item a,
.dropdown-menu__item ~ .dropdown-menu__item button {
  border-top: 1px solid #eef2f5;
}

.dropdown-menu .link-icon {
  // same with as user-avatar
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu .link-text {
  margin-left: 16px;
}

// review main-nav list item and anchor styles
.main-nav .dropdown-menu__link,
.dropdown-menu__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  color: #45555f;
  transition-property: background-color;

  @include md-transition;

  &:hover,
  &:focus {
    background-color: #eef2f5;
    color: #45555f;
  }

  &:active {
    background-color: color.adjust(#eef2f5, $lightness: -2%);
  }
}

.dropdown--with-check {
  .dropdown-menu__link {
    padding-left: 3rem;
  }

  .dropdown-menu__link--selected {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      top: 1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath stroke='%23009DE0' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' d='M7 13.2l3.8 3.8 7.7-9.5'/%3E%3C/g%3E%3C/svg%3E");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}
</style>
