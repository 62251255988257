import client from '@/api/client'

/**
 * List the dates for which there are available databases images to run
 * @param {string} projectId A string that identifies a database type
 * @returns
 */
export async function listAvailableDatabases (projectId) {
  return (await client.get(`/r2d2/databases/${projectId}/available`)).data
}

/**
 * List all databases that are running for the current user
 * @param {string} projectId A string that identifies a database type
 * @returns
 */
export async function listRunningDatabases (projectId, view = 'personal') {
  return (await client.get(`/r2d2/databases/${projectId}/running?view=${view}`)).data
}
/**
 * Stops a database instance
 * @param {string} projectId A string that identifies a database type
 * @param {number} pipelineId A number that identifies a pipeline
 * @returns
 */
export async function stopDatabaseInstance (projectId, pipelineId) {
  return (await client.post(`/r2d2/databases/${projectId}/stop/${pipelineId}`)).data
}

/**
 * Creates a database instance with the given dump
 * @param {Number} projectId The id id of the GitLab project
 * @param {string} date The date of the dump to use
 * @param {string} name Arbitrary and useless name that will probably be removed
 * @returns
 */
export async function createDatabaseInstance (projectId, date, imageName) {
  return await client.post(`/r2d2/databases/${projectId}`, { date, imageName })
}

/**
 * List all the available in which the user can create database instances
 * @returns
 */
export async function listAvailableDatabaseTypes () {
  return (await client.get('/r2d2/databases/types')).data
}

/**
 * Fetches the info about the gitlab project
 * @param {Number} projectId The id of the gitlab project
 * @returns
 */
export async function getProject (projectId) {
  return (await client.get(`/r2d2/databases/types/${projectId}`)).data
}
