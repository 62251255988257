import * as types from './mutation-types'

export default {
  [types.RECEIVED_PROFILE] (state, { profile }) {
    state.profile = profile
  },
  // [types.RECEIVE_PROFILE] (state, { profile }) {
  //   state.profile = profile
  // },
  [types.SET_TOP_MESSAGE] (state, { active, msg, dismiss, type, persist }) {
    state.topMessage = { active, msg, dismiss, type, persist: persist || false }
  },
  [types.SET_PROGRESS_BAR_STATE] (state, progress) {
    state.progressBar = progress
  }
}
