export const StatusTypes = Object.freeze({
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
})

export const ImportMethods = Object.freeze({
  GITLAB_PROJECT_ID: 'GITLAB_PROJECT_ID',
  GITLAB_PROJECT_URL: 'GITLAB_PROJECT_URL'
})

export const PROJECT_URL_ORIGIN = 'https://repo.dsi.tecnico.ulisboa.pt'
