import client from '@/api/client'

/**
 * List the decisions made by CrowdSec
 * @param {string} q An ip to use in the query to CrowdSec
 * @returns {Promise<Array>}
 */
export async function listDecisions ({ q } = {}) {
  return (await client.get('/crowd-sec', { params: { ...(q && { q }) } })).data
}
