import client from '@/api/client'
import { StatusTypes } from '@/utils/development-statistics/Projects'

export async function getProjects ({ search, status, page, perPage }) {
  const response = await client.get('/development-statistics/projects', {
    params: {
      ...(search && { search }),
      ...(status && { status }),
      ...(page !== undefined && perPage !== undefined && { skip: (page - 1) * perPage }),
      ...(page !== undefined && perPage !== undefined && { limit: perPage })
    }
  })
  return response.data
}

export async function getActiveProjects () {
  const response = await client.get('/development-statistics/projects/active')
  return response.data
}

export async function getProject ({ id }) {
  const response = await client.get(`/development-statistics/projects/${id}`)
  return response.data
}

export async function updateStatus ({ id, status }) {
  const response = await client.put(`/development-statistics/projects/${id}`, { status })
  return response.data
}

export async function activate ({ id }) {
  const response = await client.put(`/development-statistics/projects/${id}`, { status: StatusTypes.ACTIVE })
  return response.data
}

export async function deactivate ({ id }) {
  const response = await client.put(`/development-statistics/projects/${id}`, { status: StatusTypes.INACTIVE })
  return response.data
}

export async function sync ({ id }) {
  const response = await client.post(`/development-statistics/projects/${id}/sync`)
  return response.data
}

export async function syncAll () {
  const response = await client.post('/development-statistics/projects/sync')
  return response.data
}

export async function importProject ({ projectId, projectUrl }) {
  const response = await client.post('/development-statistics/projects/import', { projectId, projectUrl })
  return response.data
}

export async function importProjects ({ projectIds, projectUrls }) {
  const response = await client.post('/development-statistics/projects/import/bulk', { projectIds, projectUrls })
  return response.data
}
