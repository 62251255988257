import client from '@/api/client'

/**
 * Lists the LDAP users existent
 * @param {string} userQuery The query to search for
 * @returns {Promise<Array>} The list of LDAP users matching the query
 */
export async function listLdapUsers (userQuery) {
  const response = await client.get(`/ldap-users/search/${userQuery}`)
  return response.data
}

/**
 * Gets a specific LDAP user
 * @param {string} userUID The UID of the user to get
 * @returns {Promise<Object>} The LDAP user matching the UID
 */
export async function getLdapUser (userUID) {
  const response = await client.get(`/ldap-users/${userUID}`)
  return response.data
}

/**
 * Gets the full LDAP user
 * @param {string} userUID The UID of the user to get
 * @returns {Promise<Object>} The raw LDAP user matching the UID
 */
export async function getFullLdapUser (userUID) {
  const response = await client.get(`/ldap-users/full/${userUID}`)
  return response.data
}
