import client from '@/api/client'

export async function getAuthors ({ search, page, perPage }) {
  const response = await client.get('/development-statistics/authors', {
    params: {
      ...(search && { search }),
      ...(page !== undefined && perPage !== undefined && { skip: (page - 1) * perPage }),
      ...(page !== undefined && perPage !== undefined && { limit: perPage })
    }
  })
  return response.data
}

export async function getAuthor ({ id }) {
  const response = await client.get(`/development-statistics/authors/${id}`)
  return response.data
}

export async function updateMetadata ({ id, bot, ignore }) {
  const response = await client.put(`/development-statistics/authors/${id}/metadata`, { bot, ignore })
  return response.data
}

export async function update ({ id, gitlabUserId, gitlabUsername, name, knownUsernames, knownEmailAddresses, ignoreExistingAuthorName }) {
  const response = await client.put(`/development-statistics/authors/${id}`, { gitlabUserId, gitlabUsername, name, knownUsernames, knownEmailAddresses, ignoreExistingAuthorName })
  return response.data
}

export async function create ({ gitlabUserId, gitlabUsername, name, knownUsernames, knownEmailAddresses, ignoreExistingAuthorName }) {
  const response = await client.post('/development-statistics/authors', { gitlabUserId, gitlabUsername, name, knownUsernames, knownEmailAddresses, ignoreExistingAuthorName })
  return response.data
}
