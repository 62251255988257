<template>
  <figure
    :class="{ 'figure--icon': icon}"
    class="figure figure--avatar"
  >
    <img
      v-if="src && image"
      :src="src"
      :alt="name"
      @error="image = false"
    >
    <div
      v-if="ldapAvatar"
      class="char-avatar"
    >
      {{ name.charAt(0).toUpperCase() }}
    </div>
    <div
      v-else-if="!icon"
    >
      {{ name.charAt(0).toUpperCase() }}
    </div>
  </figure>
</template>

<script setup>
import { ref } from 'vue'
defineProps({
  src: {
    type: String,
    required: false,
    default: undefined
  },
  icon: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  sm: {
    type: Boolean,
    default: false
  },
  ldapAvatar: {
    type: Boolean,
    default: false
  }
})
const image = ref(true)
</script>

<style lang="scss">
.profile--left {
  display: flex;
  flex-direction: row;
}

.char-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.0rem;
  background-color: #009DE0;
  color: white;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.profile__avatar {
  display: inline-block;
  width: 5rem;
  height: 5rem;

  img.avatar {
    border-radius: 50%;
    width: inherit;
    height: inherit;
  }
}
</style>
