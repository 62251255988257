import client from '@/api/client'

export async function get () {
  const response = await client.get('/profile')
  return response.data
}

export async function updatePreferredLocale ({ locale }) {
  const response = await client.put('/profile', { locale })
  return response.data
}

export default {
  get,
  updatePreferredLocale
}
