import client from '@/api/client'

/**
 * Lists the LDAP groups existent
 * @param {string} groupID
 * @returns {Promise<Array>}
 */
export async function listLdapGroups (groupID) {
  const response = await client.get(`/ldap-groups/search/${groupID}`)
  return response.data
}

/**
 * Gets a specific LDAP group
 * @param {string} groupName
 * @returns {Promise<Object>}
 */
export async function getLdapGroup (groupName) {
  const response = await client.get(`/ldap-groups/${groupName}`)
  return response.data
}

/**
 * Gets the LDAP group's Unit
 * @param {string} groupName
 * @returns {Promise<Object>}
 */
export async function getUnitLdapGroup (groupName) {
  const response = await client.get(`/ldap-groups/unit/${groupName}`)
  return response.data
}

/**
 * Adds a new LDAP group Unit association
 * @param {string} groupName
 * @param {string} unitName
 * @returns {Promise<Object>}
 */
export async function addGroupUnitAssociation (groupName, unitName) {
  const response = await client.post(`/ldap-groups/add-unit/${groupName}/${unitName}`)
  return response.data
}

/**
 * Deletes a LDAP group Unit association
 * @param {string} groupName
 * @param {string} unitName
 * @returns {Promise<Object>}
 */
export async function deleteGroupUnitAssociation (groupName, unitName) {
  const response = await client.post(`/ldap-groups/delete-unit/${groupName}/${unitName}`)
  return response.data
}

/**
 * Lists the AD units existent
 * @param {string} unitID
 * @returns {Promise<Array>}
 * @returns {Promise<Array>}
 */
export async function listADUnits (unitID) {
  const response = await client.get(`/ldap-groups/unit/search/${unitID}`)
  return response.data
}

/**
 * Gets the Ldap group from a unit
 * @param {string} unitName
 * @returns {Promise<Object>}
 * @returns {Promise<Object>}
 */
export async function getLdapGroupFromUnit (unitName) {
  const response = await client.get(`/ldap-groups/unit/get/group/${unitName}`)
  return response.data
}
