import client from '@/api/client'
import dayjs from 'dayjs'
import { DATE_PICKER_FORMAT } from '@/components/utils/fields/DatePicker.vue'

export async function getMetadata () {
  const response = await client.get('/development-statistics/statistics/metadata')
  return response.data
}

export async function getGlobalStatistics ({ start, end }) {
  const response = await client.get('/development-statistics/statistics', {
    params: {
      start: dayjs(start, DATE_PICKER_FORMAT).toDate(),
      end: dayjs(end, DATE_PICKER_FORMAT).toDate()
    }
  })
  return response.data
}

export async function getProjectStatistics ({ id, start, end }) {
  const response = await client.get(`/development-statistics/statistics/${id}`, {
    params: {
      start: dayjs(start, DATE_PICKER_FORMAT).toDate(),
      end: dayjs(end, DATE_PICKER_FORMAT).toDate()
    }
  })
  return response.data
}
