import { get as getProfile } from '@/api/profile'
import * as types from './mutation-types'

export const fetchProfile = async ({ commit }) => {
  const data = await getProfile()
  commit(types.RECEIVED_PROFILE, { profile: data })
}

// export const fetchProfile = async ({ commit }) => {
//   const profile = await getProfile()
//   commit(types.RECEIVE_PROFILE, { profile })
// }

export const setTopMessage = ({ commit }, { active, msg, dismiss, type, persist }) => {
  commit(types.SET_TOP_MESSAGE, { active, msg, dismiss, type, persist })
}

export const setProgressBar = ({ commit }, progress) => {
  commit(types.SET_PROGRESS_BAR_STATE, progress)
}

export const completeProgressBar = ({ commit }) => {
  commit(types.SET_PROGRESS_BAR_STATE, 100)
}
