import { createApp } from 'vue'
import i18n, { setLocale } from '@/i18n'

import App from './App'
import store from './store'
import router from './router'
import VuePorg from 'vue-porg'
import client from '@/api/client'

import HighchartsVue from 'highcharts-vue'

/* Dayjs imports and plugins */
import 'dayjs/locale/pt'
import 'dayjs/locale/en'

import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import dayjs from 'dayjs'

dayjs.extend(CustomParseFormat)
dayjs.extend(LocalizedFormat)
/* ------------------------- */

window.addEventListener('offline', () => {
  app.sendNotification({ key: 'feedback-messages.error.network', type: 'warn', persist: true })
}, false)

window.addEventListener('online', () => {
  app.clearNotification()
}, false)

function start () {
  return createApp(App).use(VuePorg, {
    router,
    store,
    axios: client,
    default2FAChallenge: 'U2FChallenge'
  })
    .use(router)
    .use(i18n)
    .use(store)
    .use(HighchartsVue)
    .mixin({
      methods: {
        setLocale,
        sendNotification: function ({ key, message, params, type, persist }) {
          store.dispatch('setTopMessage', {
            active: true,
            msg: key
              ? {
                  pt: this.$t(key, params || {}, { locale: 'pt' }),
                  en: this.$t(key, params || {}, { locale: 'en' })
                }
              : message,
            dismiss: true,
            type,
            persist: persist || false
          })
        },
        clearNotification: function () {
          store.dispatch('setTopMessage', { active: false, msg: { pt: '', en: '' }, dismiss: false, type: '', persist: false })
        },
        notifyErrorWithKey: function (error, { type, persist } = {}) {
          if (error.key) {
            if (error.translations) {
              this.send({
                message: { pt: error.translations.pt, en: error.translations.en },
                type: type || 'danger',
                persist: persist || false
              })
            } else {
              this.sendNotification({ key: 'feedback-messages.error.internal', type: 'danger', persist: persist || false })
            }
          }
        },
        prettyBytes (num) {
          if (typeof num !== 'number' || isNaN(num)) {
            throw new TypeError('Expected a number')
          }

          const neg = num < 0
          const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

          if (neg) {
            num = -num
          }

          if (num < 1) {
            return (neg ? '-' : '') + num + ' B'
          }

          const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
          num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
          const unit = units[exponent]

          return (neg ? '-' : '') + num + ' ' + unit
        }
      }
    })
    .mount('#app')
}

const app = start()

export function getApp () {
  return app
}
